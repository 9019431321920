import React from 'react'
import Layout from '../components/layout'
import SearchInput from '../components/SearchInput'
import Seo from "../components/seo"

const HelpfulLinks = () => {
    return (
        <Layout>
            <Seo title="Helpful Links" />

            <h1 className="title">Helpful Links</h1>

            <SearchInput />

            <table>
                <tbody>
                    <tr className="thead title">
                        <td colSpan="2">Courts</td>
                    </tr>

                    <tr>
                        <td>High Court of Australia</td>
                        <td><a target="_blank" rel="noreferrer" href="https://dls.hcourt.gov.au/static/bpublicsearch ">https://dls.hcourt.gov.au/static/bpublicsearch </a></td>
                    </tr>

                    <tr className="thead title">
                        <td colSpan="2">Personnel Resources</td>
                    </tr>

                    <tr>
                        <td>Benestar</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.benestar.com/user/login">https://www.benestar.com/user/login</a></td>
                    </tr>

                    <tr>
                        <td>DiSC</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.discprofile.com/what-is-disc">https://www.discprofile.com/what-is-disc</a></td>
                    </tr>

                    <tr className="thead title">
                        <td colSpan="2">Scheduling</td>
                    </tr>

                    <tr>
                        <td>Time and date (meeting planner)</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.timeanddate.com/worldclock/meeting.html">https://www.timeanddate.com/worldclock/meeting.html</a></td>
                    </tr>

                    <tr className="thead title">
                        <td colSpan="2">Foreign Exchange</td>
                    </tr>

                    <tr>
                        <td>NAB foreign exchange calculator and rates</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.nab.com.au/personal/travel-and-overseas-banking/foreign-exchange-rates#/calculator">https://www.nab.com.au/personal/travel-and-overseas-banking/foreign-exchange-rates#/calculator</a></td>
                    </tr>

                    <tr className="thead title">
                        <td colSpan="2">Payroll and leave</td>
                    </tr>

                    <tr>
                        <td>Xero</td>
                        <td><a target="_blank" rel="noreferrer" href="https://my.xero.com/">https://my.xero.com/</a></td>
                    </tr>

                    <tr className="thead title">
                        <td colSpan="2">Client Management</td>
                    </tr>

                    <tr>
                        <td>PiP Contact Updater Tool</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.pearceip.law/contact-updater-tool/">https://www.pearceip.law/contact-updater-tool/</a></td>
                    </tr>

                    <tr className="thead title">
                        <td colSpan="2">"EU: UPC Opt Out Search", and UPC Legal Documents</td>
                    </tr>

                    <tr>
                        <td>UPC Opt Out Search</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.unified-patent-court.org/en/registry/opt-out/results?case_type&patent_number=EP1309692">UPC Opt Out Search:  Search for Opt-outs | Unified Patent Court (unified-patent-court.org)</a></td>
                    </tr>

                    <tr>
                        <td>UPC Legal Documents</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.unified-patent-court.org/en/court/legal-documents?page=2">Legal Documents | Unified Patent Court (unified-patent-court.org)</a></td>
                    </tr>
                </tbody>
            </table>
        </Layout>
    )
}

export default HelpfulLinks